export default defineNuxtRouteMiddleware(async (to) => {
  const nuxt = useNuxtApp()
  const user = await getCurrentUser()
  // redirect to login page if not logged in
  if (!user) {
    const routeName = (to.name || '').toString().replace(/___[a-z]{2}$/, '')  // elimina la parte "___es" o "___en"

    const publicRoutes = ['start', 'login', 'signup', 'remember', 'privacy', 'terms', 'gracias']

    if (!publicRoutes.includes(routeName)) {
      return navigateTo(nuxt.$localePath({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      }))
    }
  }

})
