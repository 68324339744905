import { es as vuetifyES  } from 'vuetify/locale'
import es from '../../languages/es-ES.json'
export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'es',
  messages: {
    es: {
      ...es,
      $vuetify: vuetifyES
    }
  }
}))
