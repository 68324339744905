<script setup lang="ts">
const router = useRouter()
const route = useRoute()
const localePath = useLocalePath()
const user = useCurrentUser()
const config = useRuntimeConfig()
const { locale, finalizePendingLocaleChange } = useI18n()

async function onBeforeEnter() {
  await finalizePendingLocaleChange()
}

const projectName = config.public.projectName

useHead({
  htmlAttrs: { locale: locale.value },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${projectName}` : projectName;
  }
})

onMounted(() => {
  watch(user, (currentUser, prevUser) => {
    if (prevUser && !user.value) {  // User has logged out
      router.push(localePath('/login'))
    } else if (currentUser) { // User has logged in
      if (typeof route.query.redirect === 'string') {
        router.push(localePath(route.query.redirect))
      } else {
        if (config.public.project === 'fsbd-orange-briefings') {
          router.push(localePath('/demostraciones'))
        } else {
          router.push(localePath('/'))
        }
      }
    }
  })
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage :transition="{ name: 'page', mode: 'out-in', onBeforeEnter }" />
  </NuxtLayout>
</template>

<style>
/* Page global transition */
.page-enter-active, .page-leave-active { transition: opacity 0.2s }
.page-enter-from, .page-leave-to { opacity: 0 }
/* Layout global transition */
.layout-enter-active, .layout-leave-active { transition: all 0.4s }
.layout-enter-from, .layout-leave-to { filter: blur(1rem); opacity: 0 }
</style>
